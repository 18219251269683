import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, FormControl } from 'react-bootstrap';
import { MdAccountBox, MdAccountCircle } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { getStoreState, setStoreState } from '../../store/shared';
import { useSelector } from 'react-redux';
import imag2 from "../../assets/images/windeepImage.png"

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

export default function Navbaar() {
    let token = localStorage.getItem('_token');
    console.log("token", localStorage.getItem('_token'));
    // const UserToken = getStoreState("loggedInUserReducer");
    const UserToken = useSelector((state) => state.loggedInUserReducer);
    console.log("userToken0",UserToken)
    const logout = () => {
      setStoreState("USER_LOGOUT",{})
      localStorage.removeItem('_token');
    }

    return (
      <div>
        <div className="header naavbarData" style={{ width: "100%" }}>
          <nav className="navbar navbar-expand-lg navbar-dark " style={{ background: '#000000' }}>
            <div className="container-fluid mx-auto"> {/* Added mx-auto class */}
              <Link className="navbar-brand font-weight-bold" to="/" >
                <img src={imag2} style={{ width: "120px" }} ></img>
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
  
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav">

                  {UserToken.isUserLoggedIn ? (
                    <>
                      {UserToken.isAdmin ?
                        <>
                          <li className="nav-item">
                            <NavLink className="nav-link" activclassname="active_navbar" to="/" style={{ fontSize: "1.2rem" }}>
                              Home
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" activclassname="active_navbar" to="/member" style={{ fontSize: "1.2rem" }}>
                              Member
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" activclassname="active_navbar" to="/viewRequests" style={{ fontSize: "1.2rem" }}>
                              View Requests
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" activclassname="active_navbar" to="/whatsAppMessages" style={{ fontSize: "1.2rem" }}>
                              Notification
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" activclassname="active_navbar" to="/add" style={{ fontSize: "1.2rem" }}>
                              Add
                            </NavLink>
                          </li>
			                                <li className="nav-item">
                            <NavLink className="nav-link" activclassname="active_navbar" to="/alternatemembers" style={{ fontSize: "1.2rem" }}>
                              Alternate Members
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" activclassname="active_navbar" to="/admindata" style={{ fontSize: "1.2rem" }}>
                              Administration
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" activclassname="active_navbar" to="/expenditure" style={{ fontSize: "1.2rem" }}>
                              Expenditure
                            </NavLink>
                          </li>

                        </>
                        : 
                        <>
                          <li className="nav-item ml-3">
                            <NavLink className="nav-link" activeClassName="active_navbar" to={`/member/${UserToken.member_id}/pages`} style={{ fontSize: "1.2rem" }}>
                              Member
                            </NavLink>
                          </li>
			  <li className="nav-item">
                            <NavLink className="nav-link" activclassname="active_navbar" to="/updateProfile" style={{ fontSize: "1.2rem" }}>
                              Update Profile
                            </NavLink>
                          </li>
                        </>
                      }
                      <li className="nav-item">
                        <NavLink className="nav-link" activclassname="active_navbar" to="/" onClick={()=>logout()} style={{ fontSize: "1.2rem" }}>
                          Logout
                        </NavLink>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="nav-item ml-3">
                        <NavLink className="nav-link" activclassname="active_navbar" to="/" style={{ fontSize: "1.2rem" }}>
                          Home
                        </NavLink>
                      </li>
                      <li className="nav-item ml-3">
                        <NavLink className="nav-link" activclassname="active_navbar" to="/member/login" style={{ fontSize: "1.2rem" }}>
                          Member Login
                        </NavLink>
                      </li>
                      <li className="nav-item ml-3">
                        <NavLink className="nav-link" activclassname="active_navbar" to="/admin/login" style={{ fontSize: "1.2rem" }}>
                          Admin Login
                        </NavLink>
                      </li>
                      <li className="nav-item ml-3">
                        <NavLink className="nav-link" activclassname="active_navbar" to="/add" style={{ fontSize: "1.2rem" }}>
                          Member SignUp
                        </NavLink>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </nav>


        </div>
      </div>
    );
  }
